<template>
    <div>
        <a-row :gutter="24" type="flex">

            <!-- Projects Table Column -->
            <a-col :span="24" class="mb-24">

                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12" class="top-left-header">
                                <h5 class="font-semibold m-0">Campaign History<a-spin v-if="confirmLoading"/></h5>
                            </a-col>
                            <a-col :span="24" :md="12" class="top-right-header">
                                <a-button>
                                    <export-excel
                                        :data="jsonRevenueReportData"
                                        :fields="jsonFields"
                                        worksheet="My Worksheet"
                                        :name="'campaign-history.xls'">
                                        Export
                                    </export-excel>
                                </a-button>
                                <a-range-picker v-model:dateRange="dateRange" format="YYYY-MM-DD" @change="onDateChange"/>
                                <a-radio-group v-model="campaignId" size="small">
                                    <a-radio-button value="101">101</a-radio-button>
                                    <a-radio-button value="102">102</a-radio-button>
                                    <a-radio-button value="103">103</a-radio-button>
                                </a-radio-group>
                                <a-button @click="getCampaignHistoryList">Search</a-button>
                            </a-col>
                        </a-row>
                    </template>
                    <a-table :columns="columnData"
                             :data-source="campaignHistoryList"
                             :pagination="false"
                             :scroll="{x: true}">

                        <template slot="name" slot-scope="text">
                            <a>{{ text }}</a>
                        </template>
                    </a-table>
                </a-card>

            </a-col>
            <!-- / Projects Table Column -->

        </a-row>
    </div>
</template>

<script>
import {sendRequest} from '@/http/axios.method';
import moment from "moment";

const COLUMN_DATA = [
    {
        title: 'Campaign ID',
        dataIndex: 'campaign_id',
        class: 'font-semibold text-muted',
    },
    {
        title: 'Date',
        dataIndex: 'my_date',
        class: 'font-semibold text-muted',
    },
    {
        title: 'Count Actual',
        dataIndex: 'count_actual',
        class: 'font-semibold text-muted',
    },
    {
        title: 'Count Sent',
        dataIndex: 'count_sent',
        class: 'font-semibold text-muted',
    },
];

const JSON_FIELD = {
    'Campaign ID': 'campaign_id',
    'Date': 'my_date',
    'Count Actual': 'count_actual',
    'Count Sent': 'count_sent',
}

const JSON_META = [
    [
        {
            'key': 'charset',
            'value': 'utf-8'
        }
    ]
]
export default {
    name: "CampaignHistory",
    data() {
        return {
            config: {
                'Authorization': 'Bearer ' + this.$store.state.authUser.token
            },
            columnData: COLUMN_DATA,
            campaignHistoryData: [],
            campaignHistory: [],
            campaignId: '101',
            startDate: '',
            endDate: '',
            dateRange: [],
            confirmLoading: false,
            errorMsg: '',
            jsonFields: JSON_FIELD,
            jsonRevenueReportData: [],
            json_meta: JSON_META,
        }
    },
    methods: {
        onDateChange() {
            this.startDate = '';
            this.endDate = '';

            if (Object.keys(this.dateRange).length) {
                this.startDate = moment(this.dateRange[0]).format('YYYY-MM-DD')
                this.endDate = moment(this.dateRange[1]).format('YYYY-MM-DD')
            }
        },

        getCampaignHistoryList() {
            if (this.startDate.length === 0 || this.endDate.length === 0) {
                return;
            }
            let data = {
                date_range: {
                    start: this.startDate,
                    end: this.endDate
                },
                campaign_id: this.campaignId
            }
            this.confirmLoading = true
            sendRequest('post', 'admin/campaign-history', data , this.config)
                .then(response => {
                    this.confirmLoading = false
                    if (response.success) {
                        this.campaignHistory = response.data;
                        this.campaignHistoryData = this.campaignHistory.map(function (history, label) {
                            return {
                                key: label + 1,
                                campaign_id: history.campaign_id,
                                count_actual: history.count_actual,
                                count_sent: history.count_sent,
                                my_date: history.my_date,
                            };
                        });
                    } else {
                        if (response.status_code === 401) {
                            this.$store.dispatch('logout');
                            this.$router.push('/login');
                        }
                        if (response.status_code === 500) {
                            this.campaignHistoryData = [];
                        }
                    }
                })
                .catch(() => {
                    this.confirmLoading = false
                })
                .finally(() => {
                    this.confirmLoading = false
                })
        },

        loadRevenueData(history) {
            this.jsonRevenueReportData.push({
                campaign_id: history.campaign_id,
                count_actual: history.count_actual,
                count_sent: history.count_sent,
                my_date: history.my_date,
            })
        }
    },
    computed: {
        campaignHistoryList: function () {
            this.jsonRevenueReportData = [];
            this.campaignHistory.map( history => {
                this.loadRevenueData(history)
            });
            return this.campaignHistoryData;
        }
    }
}
</script>

<style scoped>

</style>

